import "core-js/modules/es.error.cause.js";
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0c0c5104"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "register-panel"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "captcha-wrapper"
};
const _hoisted_4 = {
  class: "img-wrap"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "new-btn-wrap"
};
const _hoisted_7 = {
  class: "top"
};
const _hoisted_8 = {
  class: "center"
};
import DecorateLine from '@/components/decorate-line';
import { rules } from '../config/account-config';
import { reactive, ref, watch, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import useUserStroe from '@/store/modules/user.js';
import { useRouter } from 'vue-router';
import { isEmailFormat, reg_pass } from '@/utils/validateFormate.js';
import { userRegister } from '@/service/register/register.js';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'register-panel',
  emits: ['changeModal'],

  setup(__props, {
    emit
  }) {
    const {
      t
    } = useI18n();
    const formRef = ref(null);
    const account = reactive({
      username: '',
      email: '',
      pass: '',
      checkPass: '',
      captcha_text: ''
    });
    const userStore = useUserStroe();
    const router = useRouter(); // 注册处理

    function handleRegister() {
      const formData = {
        username: account.username,
        email: account.email,
        password: account.pass,
        captcha_text: account.captcha_text,
        captcha_img: userStore.image_id
      };
      userRegister(formData).then(res => {
        if (res.code == 200) {
          // 1. 提交到store
          userStore.accountRegister({
            email: res.result.email,
            user_id: res.result.user_id,
            username: res.result.username,
            csrf_token: res.result.csrf_token
          }); // 2. 缓存注册信息

          window.sessionStorage.setItem('registerAccountForm', JSON.stringify(account)); // 3. 下一步

          handleRegisterNextClick();
        } else {
          userStore.fetchCaptchaImage();
          ElMessage({
            message: res.message,
            type: 'warning'
          });
        }
      });
    }

    const handleRegisterNextClick = () => {
      window.sessionStorage.removeItem('isLoginToRegisterEmail');
      emit('changeModal', 3);
    }; // 注册表单 下一步


    const handleRegisterClick = () => {
      var _formRef$value;

      // 验证通过后
      // 缓存
      (_formRef$value = formRef.value) === null || _formRef$value === void 0 ? void 0 : _formRef$value.validate(valid => {
        if (valid) {
          // 1. 注册请求
          handleRegister();
        }
      });
    };

    onMounted(() => {
      var _JSON$parse;

      const registerAccountForm = (_JSON$parse = JSON.parse(window.sessionStorage.getItem('registerAccountForm'))) !== null && _JSON$parse !== void 0 ? _JSON$parse : undefined;

      if (registerAccountForm) {
        Object.assign(account, { ...registerAccountForm
        });
        account.captcha_text = '';
      }
    });

    const handleToLoginClick = () => {
      router.push('/login');
    }; // 输入密码时才显示验证码


    const isLoadCaptcha = ref(false);
    watch(() => account.pass, newVal => {
      if (newVal) {
        isLoadCaptcha.value = true;
      }
    });
    watch(isLoadCaptcha, newVal => {
      if (newVal) {
        userStore.fetchCaptchaImage();
      }
    }); // 刷新验证码

    const handleRefreshVerifyImgClick = () => {
      userStore.fetchCaptchaImage();
    }; // 密码校验


    const validatePass = (rule, value, callback) => {
      if (value === '') {
        // 密码不能为空
        callback(new Error(t('register.form_rules.password.required')));
      } else if (value.length < 5) {
        callback(new Error(t('register.form_rules.password.length')));
      } else {
        callback();
      }
    };

    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        // 请再次输入密码
        callback(new Error(t('register.form_rules.confirm_password.required')));
      } else if (value != account.pass) {
        // 两次输入的密码不一致
        callback(new Error(t('register.form_rules.confirm_password.no_equal')));
      } else {
        callback();
      }
    };

    const formRule = { ...rules,
      pass: [{
        required: true,
        message: t('register.form_rules.password.required'),
        trigger: ['blur', 'change']
      }, {
        validator: validatePass,
        trigger: ['blur']
      }],
      checkPass: [{
        required: true,
        message: t('register.form_rules.confirm_password.required'),
        trigger: ['blur', 'change']
      }, {
        validator: validatePass2,
        trigger: ['blur']
      }]
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_link = _resolveComponent("el-link");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(DecorateLine), {
        multiple: 2
      }), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('register.register_title')), 1), _createVNode(_component_el_form, {
        model: account,
        ref_key: "formRef",
        ref: formRef,
        "hide-required-asterisk": "",
        "label-position": "left",
        rules: formRule,
        size: "large"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          prop: "username"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: account.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => account.username = $event),
            autocomplete: "off",
            placeholder: _ctx.$t('register.username'),
            onKeydown: _withKeys(handleRegisterClick, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: "email"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: account.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => account.email = $event),
            autocomplete: "off",
            placeholder: _ctx.$t('register.email'),
            onKeydown: _withKeys(handleRegisterClick, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: "pass"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: account.pass,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => account.pass = $event),
            "show-password": "",
            autocomplete: "off",
            placeholder: _ctx.$t('register.password'),
            onKeydown: _withKeys(handleRegisterClick, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: "checkPass"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: account.checkPass,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => account.checkPass = $event),
            "show-password": "",
            autocomplete: "off",
            placeholder: _ctx.$t('register.confirm_password'),
            onKeydown: _withKeys(handleRegisterClick, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
          _: 1
        }), isLoadCaptcha.value ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          prop: "captcha_text"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, {
            class: "verifi-box"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: account.captcha_text,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => account.captcha_text = $event),
                placeholder: _ctx.$t('register.captcha_text'),
                onKeydown: _withKeys(handleRegisterClick, ["enter"])
              }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
                src: _unref(userStore).image_url,
                alt: "",
                onClick: handleRefreshVerifyImgClick
              }, null, 8, _hoisted_5), _createVNode(_component_el_link, {
                type: "primary",
                onClick: handleRefreshVerifyImgClick
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('register.refresh_captcha')), 1)]),
                _: 1
              })])])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["model"]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button, {
        type: "primary",
        class: "next-btn",
        onClick: handleRegisterClick
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('register.next_btn')), 1)]),
        _: 1
      })]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_link, {
        type: "primary",
        class: "login-btn",
        onClick: handleToLoginClick
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('register.has_account')), 1)]),
        _: 1
      })])])]);
    };
  }

};