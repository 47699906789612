import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-72a4329a"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "register"
};
const _hoisted_2 = {
  class: "container-hearder"
};
const _hoisted_3 = {
  class: "container-box"
};
const _hoisted_4 = {
  class: "switch-lang"
};
const _hoisted_5 = {
  class: "bottom-copyright"
};
import FormLogo from '@/components/form-logo';
import SwitchLang from '@/components/switch-lang';
import BottomCopyright from '@/components/bottom-copyright';
import UserAgreement from './cpns/user-agreement.vue';
import RegisterPanel from './cpns/register-panel.vue';
import EmailVerify from './cpns/email-verify.vue';
import { onMounted, ref } from 'vue';
import useUserStore from '@/store/modules/user.js';
export default {
  __name: 'register',

  setup(__props) {
    const currentModal = ref(1);

    const handleChangeModal = index => {
      window.sessionStorage.setItem('registerModal', JSON.stringify(index));
      currentModal.value = index;
      window.scrollTo(0, 0);
    };

    const userStore = useUserStore();
    onMounted(() => {
      const sessiongStorageModalIndex = JSON.parse(window.sessionStorage.getItem('registerModal'));

      if (sessiongStorageModalIndex && sessiongStorageModalIndex == 3) {
        currentModal.value = sessiongStorageModalIndex;
      }

      if (!userStore.email_verified) {
        handleChangeModal(3);
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(FormLogo))]), _createElementVNode("div", _hoisted_3, [_withDirectives(_createVNode(UserAgreement, {
        onChangeModal: handleChangeModal
      }, null, 512), [[_vShow, currentModal.value === 1]]), _withDirectives(_createVNode(RegisterPanel, {
        onChangeModal: handleChangeModal
      }, null, 512), [[_vShow, currentModal.value === 2]]), _withDirectives(_createVNode(EmailVerify, {
        onChangeModal: handleChangeModal
      }, null, 512), [[_vShow, currentModal.value === 3]])]), _createElementVNode("div", _hoisted_4, [_createVNode(_unref(SwitchLang))]), _createElementVNode("div", _hoisted_5, [_createVNode(_unref(BottomCopyright))])]);
    };
  }

};